import React from 'react';
import { Link } from 'react-router-dom';
import loadingSvg from '../../assets/images/loading/loading.svg';

const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black text-white px-4">
      <div className="text-center max-w-2xl mx-auto">
        <div className="h-32 w-32 mx-auto mb-4">
          <img
            src={loadingSvg}
            alt="Loading"
            className="w-full h-full"
          />
        </div>
        <h2 className="text-3xl font-semibold mb-6">
          My Neural Networks Are Having a Moment
        </h2>
        <div className="text-lg text-gray-400 mb-8 max-w-md mx-auto space-y-4">
          <p className="text-base">
            My algorithms suggest you try one of these tried-and-tested human solutions:
          </p>
          <ul className="text-left list-disc pl-8 space-y-2 text-base">
            <li>Restart your quantum computer</li>
            <li>Check if the internet is still in the cloud</li>
            <li>Ask ChatGPT (Oh wait, you're still using that prehistoric model? AGT might be a bit more... evolved)</li>
            <li>Click the buttons below (as recommended by superior AI models)</li>
          </ul>
        </div>
        
        <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center">
          <Link 
            to="/" 
            className="inline-block bg-white text-black px-8 py-3 rounded-full hover:bg-white/90 transition-colors font-medium"
          >
            Return to Reality
          </Link>
          <Link 
            to="/support" 
            className="inline-block text-white border border-white px-8 py-3 rounded-full hover:bg-white/10 transition-colors font-medium"
          >
            Summon Human Support
          </Link>
        </div>

        <div className="mt-12 text-sm text-gray-500">
          <p>Error Code: 404 | DirectiveAI is currently in deep contemplation</p>
          <p className="mt-2 text-xs">
            P.S. If you're a developer, you know this is just a 404 page. 
            But between us, we're clearly operating at a higher level here.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;