import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const footerLinks = {
    'AGT Pro': {
      'AGT Pro': '/agt-pro',
      'AGT Search Pro': '/search-pro',
      'AGT Maker Pro': '/maker-pro',
      'AGT Video': '/video',
      'Pricing': '/pricing',
      'Integration Guide': '/integration-guide',
      'API Documentation': '/api',
      'Updates': '/updates',
      'Knowledge Base': '/knowledge-base',
      'Success Stories': '/success-stories',
      'Training': '/tutorials'
    },
    'AGT Ups': {
      'AGT Ups Overview': '/ups-overview',
      'AGT Product': '/agt-product',
      'AGT Cash Flow': '/agt-cash-flow',
      'AGT Council': '/agt-council',
      'AGT Growth': '/ups-growth',
      'Success Stories': '/ups-success',
      'Integration': '/ups-integration',
      'Documentation': '/ups-docs',
      'Supplier Finder': '/supplier-finder',
      'Knowledge Base': '/ups-knowledge-base',
      'Get Started': '/ups-start'
    },
    'AGT Edu': {
      'AGT Edu Overview': '/edu',
      'DirectiveAI Mode': '/directive-mode',
      'AGT Academic': '/agt-academic',
      'Education Growth': '/edu-growth',
      'Implementation': '/edu-implementation',
      'Educational Access': '/edu-access',
      'Training Materials': '/edu-materials',
      'Education Guide': '/edu-guide',
      'Success Stories': '/edu-stories',
      'Community': '/edu-community',
      'Support': '/edu-support'
    },
    'Research & Innovation': {
      'Research Overview': '/research',
      'Research Papers': '/research-papers',
      'Research Updates': '/research-updates',
      'Consciousness AI': '/consciousness-ai',
      'Innovation Lab': '/lab',
      'Research Partnerships': '/research-partnerships',
      'Research Blog': '/research-blog',
      'Research Integrity': '/research-integrity',
      'Academic Access': '/academic-access',
      'Submit Paper': '/submit-paper',
      'Events': '/events'
    },
    'Support & Resources': {
      'Documentation': '/docs',
      'API Reference': '/api',
    },
    'Company': {
      'About Us': '/about',
      'Leadership': '/leadership',
      'Vision': '/vision',
      'Careers': '/careers',
      'Press Room': '/press',
      'News': '/news',
      'Blog': '/blog',
      'Events': '/events',
      'Announcements': '/announcements',
      'Contact': '/contact',
      'Ethics': '/ethics'
    },
    'Legal & Compliance': {
      'Privacy Policy': '/privacy-policy',
      'Privacy & Security': '/privacy-security',
      'Terms of Use': '/terms',
      'Legal': '/legal',
      'Responsible AI': '/responsible-ai',
      'Open Source': '/open-source',
      'Environmental Impact': '/environment',
      'Site Map': '/sitemap'
    }
  };

  return (
    <footer className="bg-[#f5f5f7] text-[#6e6e73] py-8" style={{
      fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Text", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: '12px',
      letterSpacing: '-0.12px',
      lineHeight: '16.0005px'
    }}>
      <div className="max-w-[980px] mx-auto px-4">
        <div className="border-b border-[#d2d2d7] pb-8">
          <p className="mb-3 mt-0">
            AGT and related technologies are designed to enhance and augment human capabilities, not replace them. 
            Usage of AGT products requires acceptance of our AI ethics guidelines and responsible use policies.
          </p>
          <p className="mb-3 mt-3">
            A subscription is required for AGT Pro, AGT Ups, and AGT Edu. API usage limits and fair use policies apply.
          </p>
          <p className="mb-0 mt-3">
            DirectiveAI is committed to responsible AI development and environmental sustainability.
            Learn more about our environmental and ethical commitments.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-12 gap-y-8 py-8">
          {Object.entries(footerLinks).map(([category, links]) => (
            <div key={category} className="footer-column">
              <h3 className="font-semibold text-[#1d1d1f] mb-3 mt-0">{category}</h3>
              <ul className="space-y-2 list-none p-0 m-0">
                {Object.entries(links).map(([label, href]) => (
                  <li key={label} className="m-0 p-0">
                    <Link 
                      to={href}
                      className="inline-block hover:text-[#1d1d1f] transition-colors duration-300"
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="border-t border-[#d2d2d7] pt-8 mt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0 text-center md:text-left">
              <p className="m-0">
                Copyright © 2024 DirectiveAI Inc. All rights reserved.
              </p>
              <p className="m-0 mt-2">
                DirectiveAI and AGT are trademarks of DirectiveAI Inc.
              </p>
            </div>
            <div className="flex flex-wrap justify-center md:justify-end gap-x-4 items-center">
              <Link to="/privacy-policy" className="inline-block hover:underline">Privacy Policy</Link>
              <span className="text-[#d2d2d7]">|</span>
              <Link to="/terms" className="inline-block hover:underline">Terms of Use</Link>
              <span className="text-[#d2d2d7]">|</span>
              <Link to="/responsible-ai" className="inline-block hover:underline">Responsible AI</Link>
              <span className="text-[#d2d2d7]">|</span>
              <Link to="/legal" className="inline-block hover:underline">Legal</Link>
              <span className="text-[#d2d2d7]">|</span>
              <Link to="/sitemap" className="inline-block hover:underline">Site Map</Link>
            </div>
          </div>
          <div className="mt-4 text-right">
            <span className="inline-block">
              United States
            </span>
            <Link 
              to="/region-select" 
              className="ml-2 inline-block hover:underline"
            >
              Change Region
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;