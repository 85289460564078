import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  MagnifyingGlassIcon, 
  Bars3Icon, 
  XMarkIcon, 
  ChevronRightIcon
} from '@heroicons/react/24/outline';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeMobileSubmenu, setActiveMobileSubmenu] = useState(null);
  const location = useLocation();

  const navigation = [
    {
      name: 'AGT',
      href: '/agt',
      submenu: {
        'Discover AGT': {
          'Overview': '/agt',
          'AGT Pro': '/agt-pro',
          'AGT Search Pro': '/search-pro',
          'AGT Maker Pro': '/maker-pro',
          'AGT Video': '/video',
          'Pricing': '/pricing',
          'Updates': '/updates'
        },
        'Get Started': {
          'Integration Guide': '/integration-guide',
          'API Documentation': '/api',
          'Documentation': '/docs',
          'Training': '/tutorials',
          'Knowledge Base': '/knowledge-base'
        },
        'Support & Community': {
          'Community': '/community',
          'Support Center': '/support',
          'Success Stories': '/success-stories'
        }
      }
    },
    {
      name: 'AGT Ups',
      href: '/ups-overview',
      submenu: {
        'Explore AGT Ups': {
          'Overview': '/ups-overview',
          'AGT Product': '/agt-product',
          'AGT Cash Flow': '/agt-cash-flow',
          'AGT Council': '/agt-council',
          'AGT Growth': '/ups-growth',
          'Get Started': '/ups-start',
          'Supplier Finder': '/supplier-finder'
        },
        'Resources & Support': {
          'Integration': '/ups-integration',
          'Documentation': '/ups-docs',
          'Knowledge Base': '/ups-knowledge-base',
          'Training': '/ups-training',
          'Success Stories': '/ups-success'
        },
        'Community': {
          'Community Hub': '/ups-community',
          'Support': '/ups-support'
        }
      }
    },
    {
      name: 'AGT Edu',
      href: '/edu',
      submenu: {
        'Explore Education': {
          'Overview': '/edu',
          'DirectiveAI Mode': '/directive-mode',
          'AGT Academic': '/agt-academic',
          'AGT Growth': '/edu-growth',
          'Materials': '/edu-materials',
          'Success Stories': '/edu-stories'
        },
        'Get Started': {
          'Educational Pricing': '/edu-pricing',
          'Implementation': '/edu-implementation',
          'Documentation': '/edu-docs',
          'Training': '/edu-training'
        },
        'Resources': {
          'Support': '/edu-support',
          'Community': '/edu-community',
          'Access Portal': '/edu-access',
          'Educational Guide': '/edu-guide'
        }
      }
    },
    {
      name: 'Research',
      href: '/research',
      submenu: {
        'Research Areas': {
          'Overview': '/research',
          'Consciousness AI': '/consciousness-ai',
          'Innovation Lab': '/lab',
          'Research Papers': '/research-papers',
          'Research Updates': '/research-updates'
        },
        'Collaboration': {
          'Research Partnerships': '/research-partnerships',
          'Research Integrity': '/research-integrity',
          'Academic Access': '/academic-access',
          'Submit Paper': '/submit-paper'
        },
        'Resources': {
          'Research Blog': '/research-blog',
          'Events': '/events',
          'Ethics Guidelines': '/ethics'
        }
      }
    },
    {
      name: 'Company',
      href: '/about',
      submenu: {
        'About Us': {
          'Overview': '/about',
          'Leadership': '/leadership',
          'Vision': '/vision',
          'Ethics': '/ethics',
          'Environmental Impact': '/environment'
        },
        'News & Updates': {
          'Press Room': '/press',
          'News': '/news',
          'Blog': '/blog',
          'Events': '/events',
          'Announcements': '/announcements'
        },
        'Connect': {
          'Careers': '/careers',
          'Contact': '/contact',
          'Support': '/company-support'
        }
      }
    }
  ];

  const quickLinks = [
    { name: 'AGT Pro', href: '/agt-pro' },
    { name: 'AGT Search Pro', href: '/search-pro' },
    { name: 'AGT Video', href: '/video' },
    { name: 'AGT Maker Pro', href: '/maker-pro' },
    { name: 'Integration Guide', href: '/integration-guide' },
    { name: 'Documentation', href: '/docs' },
    { name: 'Support', href: '/support' },
    { name: 'Community', href: '/community' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (!isMobileMenuOpen && !isSearchOpen && !isMobile) {
        setIsScrolled(window.scrollY > 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobileMenuOpen, isSearchOpen, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setActiveMenu(null);
    setIsMobileMenuOpen(false);
    setIsSearchOpen(false);
    setActiveMobileSubmenu(null);
  }, [location]);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
    setActiveMenu(null);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
    setSearchQuery('');
    setSearchResults([]);
  };

  const SearchResults = () => (
    <div className="max-w-2xl mx-auto w-full">
      {searchQuery ? (
        searchResults.length > 0 ? (
          <div className="space-y-5">
            {searchResults.map((result, index) => (
              <Link
                key={index}
                to={result.href}
                className="flex items-center text-gray-300 hover:text-white text-lg"
                onClick={handleSearchClose}
              >
                <ChevronRightIcon className="w-5 h-5 mr-3 text-gray-400" />
                <span>{result.name}</span>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-gray-400">No results found</p>
        )
      ) : (
        <>
          <h3 className="text-gray-400 text-base font-medium mb-4">Quick Links</h3>
          <div className="space-y-5">
            {quickLinks.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="flex items-center text-gray-300 hover:text-white text-lg"
                onClick={handleSearchClose}
              >
                <ChevronRightIcon className="w-5 h-5 mr-3 text-gray-400" />
                <span>{item.name}</span>
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );

  const MobileMenu = () => {
    return (
      <div className="fixed inset-0 z-[9999] h-screen bg-black">
        <div className="relative flex h-full overflow-hidden">
          <motion.div 
            className="absolute inset-0 w-full h-full"
            animate={{
              x: activeMobileSubmenu ? '-100%' : '0%',
              opacity: activeMobileSubmenu ? 0 : 1
            }}
            transition={{ 
              type: "tween",
              duration: 0.5, // Aumentado de 0.3 a 0.5 segundos
              ease: "easeInOut"
            }}
          >
            <div className="sticky top-0 flex items-center justify-between px-8 py-4 border-b border-gray-800">
              <Link to="/" className="text-white font-medium text-xl">
                DirectiveAI
              </Link>
              <button
                onClick={() => setIsMobileMenuOpen(false)}
                className="text-gray-400 hover:text-white p-2"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>
            
            <div className="h-[calc(100vh-64px)] overflow-y-auto">
              <div className="px-8 py-6">
                {navigation.map((item) => (
                  <motion.button
                    key={item.name}
                    className="flex items-center justify-between w-full py-4 text-xl text-gray-200 hover:text-white font-medium"
                    onClick={() => setActiveMobileSubmenu(item)}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -40 }} // Añadido efecto de salida
                    transition={{ duration: 0.4 }} // Aumentado duración
                  >
                    <span>{item.name}</span>
                    <ChevronRightIcon className="w-5 h-5 text-gray-400" />
                  </motion.button>
                ))}
              </div>
            </div>
          </motion.div>
  
          <motion.div 
            className="absolute inset-0 w-full h-full"
            initial={{ x: '100%' }} // Asegura que siempre empiece desde la derecha
            animate={{
              x: activeMobileSubmenu ? '0%' : '100%',
              opacity: activeMobileSubmenu ? 1 : 0
            }}
            transition={{ 
              type: "tween",
              duration: 0.5, // Aumentado para coincidir con la otra transición
              ease: "easeInOut",
              delay: activeMobileSubmenu ? 0.2 : 0 // Pequeño retraso al entrar
            }}
          >
            {activeMobileSubmenu && (
              <>
                <div className="sticky top-0 flex items-center justify-between px-8 py-4 border-b border-gray-800">
                <div className="flex items-center">
                  <button
                    onClick={() => setActiveMobileSubmenu(null)}
                    className="text-gray-300 hover:text-white p-2 -ml-2 flex items-center"
                  >
                    <ChevronRightIcon className="w-6 h-6 transform rotate-180" />
                    <span className="ml-1 text-base font-medium"></span>
                  </button>
                </div>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="text-gray-400 hover:text-white p-2"
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
              </div>
                
                <div className="h-[calc(100vh-64px)] overflow-y-auto">
                  <div className="px-8 py-6 space-y-8">
                    {Object.entries(activeMobileSubmenu.submenu).map(([category, links], index) => (
                      <motion.div 
                        key={category} 
                        className="space-y-4"
                        initial={{ opacity: 0, x: 40 }} // Inicia desde la derecha
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ 
                          duration: 0.4,
                          delay: 0.3 + (index * 0.1) // Efecto escalonado para cada categoría
                        }}
                      >
                        <h3 className="text-gray-400 font-medium text-sm uppercase tracking-wider">
                          {category}
                        </h3>
                        <div className="space-y-4">
                          {Object.entries(links).map(([label, href], linkIndex) => (
                            <motion.div
                              key={label}
                              initial={{ opacity: 0, x: 20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.3,
                                delay: 0.4 + (index * 0.1) + (linkIndex * 0.05) // Enlaces aparecen después de las categorías
                              }}
                            >
                              <Link
                                to={href}
                                className="block text-gray-300 hover:text-white text-lg font-medium"
                                onClick={() => {
                                  setIsMobileMenuOpen(false);
                                  setActiveMobileSubmenu(null);
                                }}
                              >
                                {label}
                              </Link>
                            </motion.div>
                          ))}
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </motion.div>
        </div>
      </div>
    );
  };

  return (
    <>
      <motion.header
        className="fixed top-0 w-full z-50"
        initial={false}
      >
        <nav className={`w-full ${isMobile ? 'bg-black' : 
          `transition-all duration-300 ${
            isScrolled ? 'bg-black/80 backdrop-blur-md' : 'bg-black/95'
          }`
        }`}>
          <div className="mx-auto px-4">
            <div className="flex justify-between h-[44px] items-center">
              <button
                className="md:hidden text-gray-300 hover:text-white p-2"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <Bars3Icon className="w-6 h-6" />
              </button>

              <Link to="/" className="text-white font-medium text-xl">
                DirectiveAI
              </Link>
              
              <div className="hidden md:flex items-center space-x-9">
                {navigation.map((item) => (
                  <div
                    key={item.name}
                    className="relative"
                    onMouseEnter={() => setActiveMenu(item.name)}
                  >
                    <Link
                      to={item.href}
                      className={`text-[12px] font-light tracking-wide transition-colors duration-200 hover:text-white ${
                        location.pathname === item.href || activeMenu === item.name
                          ? 'text-white'
                          : 'text-gray-300'
                      }`}
                    >
                      {item.name}
                    </Link>
                  </div>
                ))}
              </div>

              <button
                className="text-gray-400 hover:text-white p-2"
                onClick={handleSearchClick}
              >
                <MagnifyingGlassIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        </nav>

        <AnimatePresence>
          {activeMenu && !isMobileMenuOpen && !isSearchOpen && !isMobile && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="absolute top-[44px] left-0 w-full bg-black/80 backdrop-blur-md z-40"
              onMouseLeave={() => setActiveMenu(null)}
            >
              <div className="max-w-7xl mx-auto py-8 px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6gap-y-4">
                  {Object.entries(navigation.find(item => item.name === activeMenu)?.submenu || {}).map(([category, links], columnIndex) => (
                    <div key={category} className="space-y-4">
                      <h3 className="text-gray-400 font-medium text-sm mb-4">{category}</h3>
                      <div className="space-y-3">
                        {Object.entries(links).map(([label, href]) => (
                          <Link
                            key={label}
                            to={href}
                            className={`block ${
                              columnIndex === 0 ? 'text-lg' : columnIndex === 1 ? 'text-base' : 'text-sm'
                            } text-gray-300 hover:text-white font-medium leading-tight tracking-normal transition-colors duration-200`}
                          >
                            {label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.header>

      <AnimatePresence mode="wait">
        {isMobileMenuOpen && <MobileMenu />}
      </AnimatePresence>

      <AnimatePresence>
        {isSearchOpen && (
          <div className="fixed inset-0 z-[9999] h-full w-full bg-black">
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ 
                type: "tween",
                duration: 0.25,
                ease: "easeOut"
              }}
              className="h-full w-full"
            >
              <div className="max-w-7xl mx-auto py-8 px-4">
                <div className="flex items-center justify-between max-w-2xl mx-auto mb-6">
                  <div className="flex items-center flex-1">
                    <MagnifyingGlassIcon className="w-5 h-5 text-gray-400 mr-3" />
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => {
                        const query = e.target.value;
                        setSearchQuery(query);
                        if (query.trim()) {
                          const filteredResults = navigation.flatMap(section => {
                            const menuItems = Object.values(section.submenu).flatMap(submenu => 
                              Object.entries(submenu).map(([name, href]) => ({ name, href }))
                            );
                            return menuItems;
                          }).filter(item => 
                            item.name.toLowerCase().includes(query.toLowerCase())
                          );
                          setSearchResults(filteredResults.slice(0, 5));
                        } else {
                          setSearchResults([]);
                        }
                      }}
                      placeholder="Search directiveai.com"
                      className="bg-transparent text-gray-400 text-lg focus:outline-none w-full"
                      autoFocus
                    />
                  </div>
                  <button
                    onClick={handleSearchClose}
                    className="text-gray-400 hover:text-white p-2"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>
                
                <SearchResults />
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;