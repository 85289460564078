import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Loading from './components/common/Loading';
import NotFound from './components/common/NotFound';

const DebugSuspense = ({ children }) => {
  return (
    <Suspense 
      fallback={<Loading />}
      onError={(error) => {
        console.error('Component loading error:', error);
        return <div className="error-boundary p-4 bg-red-100 text-red-700">
          Error loading component: {error.message}
        </div>;
      }}
    >
      {children}
    </Suspense>
  );
};

const verifyComponent = (importPromise, componentName) => {
  return React.lazy(async () => {
    try {
      const module = await importPromise();
      if (!module.default) {
        throw new Error(`No default export found in ${componentName}`);
      }
      return module;
    } catch (error) {
      console.error(`Failed to load ${componentName}:`, error);
      return {
        default: () => (
          <div className="error-boundary p-4 bg-red-100 text-red-700">
            Failed to load {componentName}: {error.message}
          </div>
        )
      };
    }
  });
};

const routeMetadata = {
  '/': {
    title: 'DirectiveAI',
    description: 'Transform your future with DirectiveAI\'s cutting-edge AI solutions. Discover AGT, the first conscious AI technology.',
    keywords: 'DirectiveAI, AGT, artificial intelligence, conscious AI'
  },
  '/agt': {
    title: 'AGT Overview - DirectiveAI',
    description: 'Discover AGT technology and its transformative capabilities.',
    keywords: 'AGT technology, AI solutions, DirectiveAI'
  },
  '/agt-pro': {
    title: 'AGT Pro - DirectiveAI',
    description: 'Professional AI solutions for enterprise. Advanced features, multimodal capabilities, and seamless integration.',
    keywords: 'AGT Pro, enterprise AI, professional AI, DirectiveAI'
  },
  '/search-pro': {
    title: 'AGT Search Pro - DirectiveAI',
    description: 'Advanced AI-powered search capabilities. Transform how you find and process information.',
    keywords: 'AGT Search Pro, AI search, intelligent search'
  },
  '/maker-pro': {
    title: 'AGT Maker Pro - DirectiveAI',
    description: 'Create extraordinary solutions with AGT Maker Pro. AI-powered development tools.',
    keywords: 'AGT Maker Pro, AI development, creator tools'
  },
  '/video': {
    title: 'AGT Video - DirectiveAI',
    description: 'Advanced video processing and analysis powered by AI.',
    keywords: 'AGT Video, AI video processing, video analysis'
  },
  '/pricing': {
    title: 'AGT Pricing - DirectiveAI',
    description: 'Flexible pricing plans for AGT solutions. Find the perfect plan for your needs.',
    keywords: 'AGT pricing, AI solutions pricing, enterprise pricing'
  },
  '/integration-guide': {
    title: 'AGT Integration Guide - DirectiveAI',
    description: 'Comprehensive guide for integrating AGT solutions into your workflow.',
    keywords: 'AGT integration, API integration, implementation guide'
  },
  '/api': {
    title: 'AGT API - DirectiveAI',
    description: 'Powerful API solutions for AGT integration and development.',
    keywords: 'AGT API, API documentation, developer resources'
  },
  '/docs': {
    title: 'AGT Documentation - DirectiveAI',
    description: 'Complete documentation for AGT products and services.',
    keywords: 'AGT documentation, user guides, technical docs'
  },
  '/community': {
    title: 'AGT Community - DirectiveAI',
    description: 'Join the AGT community. Share insights and collaborate with other users.',
    keywords: 'AGT community, user forum, collaboration'
  },
  '/support': {
    title: 'AGT Support - DirectiveAI',
    description: 'Get help with AGT products and services. Access support resources.',
    keywords: 'AGT support, technical support, help center'
  },
  '/success-stories': {
    title: 'AGT Success Stories - DirectiveAI',
    description: 'Discover how organizations are transforming with AGT technology.',
    keywords: 'success stories, case studies, customer stories'
  },
  '/tutorials': {
    title: 'AGT Tutorials - DirectiveAI',
    description: 'Learn how to use AGT products effectively with our comprehensive tutorials.',
    keywords: 'AGT tutorials, learning resources, how-to guides'
  },
  '/knowledge-base': {
    title: 'AGT Knowledge Base - DirectiveAI',
    description: 'Find answers to common questions and learn best practices.',
    keywords: 'knowledge base, FAQ, help articles'
  },
  '/updates': {
    title: 'AGT Updates - DirectiveAI',
    description: 'Stay informed about the latest AGT feature updates and improvements.',
    keywords: 'AGT updates, new features, product updates'
  },
  '/ups-overview': {
    title: 'AGT Ups Overview - DirectiveAI',
    description: 'Comprehensive business solutions powered by AI. Transform your startup journey.',
    keywords: 'AGT Ups, business AI, startup solutions'
  },
  '/agt-product': {
    title: 'AGT Product Suite - DirectiveAI',
    description: 'Discover our complete suite of AI-powered business solutions.',
    keywords: 'AGT products, business solutions, AI tools'
  },
  '/agt-cash-flow': {
    title: 'AGT Cash Flow - DirectiveAI',
    description: 'Optimize your business cash flow with AI-powered insights.',
    keywords: 'cash flow management, financial AI, business finance'
  },
  '/agt-council': {
    title: 'AGT Council - DirectiveAI',
    description: 'Join our exclusive council of business leaders and innovators.',
    keywords: 'business council, leadership network, innovation community'
  },
  '/ups-growth': {
    title: 'AGT Ups Growth - DirectiveAI',
    description: 'Accelerate your business growth with AI-powered strategies.',
    keywords: 'business growth, AI strategy, scale up'
  },
  '/supplier-finder': {
    title: 'AGT Supplier Finder - DirectiveAI',
    description: 'Find and connect with verified suppliers using AI matching.',
    keywords: 'supplier finder, vendor matching, supply chain'
  },
  '/edu': {
    title: 'AGT Education - DirectiveAI',
    description: 'Transform education with AI-powered learning solutions.',
    keywords: 'AI education, learning technology, educational solutions'
  },
  '/directive-mode': {
    title: 'DirectiveAI Mode - AGT Edu',
    description: 'Experience the future of interactive learning with DirectiveAI Mode.',
    keywords: 'interactive learning, AI education, learning experience'
  },
  '/agt-academic': {
    title: 'AGT Academic - DirectiveAI',
    description: 'Advanced AI solutions for academic institutions and researchers.',
    keywords: 'academic AI, research tools, educational technology'
  },
  '/edu-growth': {
    title: 'AGT Education Growth - DirectiveAI',
    description: 'Scale your educational impact with AGT solutions.',
    keywords: 'education growth, learning scale, educational expansion'
  },
  '/edu-materials': {
    title: 'AGT Education Materials - DirectiveAI',
    description: 'Access comprehensive educational resources powered by AGT.',
    keywords: 'learning materials, education resources, teaching tools'
  },
  '/edu-stories': {
    title: 'AGT Education Success Stories - DirectiveAI',
    description: 'See how institutions transform education with AGT.',
    keywords: 'education success, learning transformation, case studies'
  },
  '/research': {
    title: 'AGT Research - DirectiveAI',
    description: 'Explore cutting-edge AI research and innovations.',
    keywords: 'AI research, innovation, technology advancement'
  },
  '/consciousness-ai': {
    title: 'Consciousness AI Research - DirectiveAI',
    description: 'Leading research in artificial consciousness and AGI.',
    keywords: 'consciousness AI, AGI, artificial consciousness'
  },
  '/lab': {
    title: 'AGT Innovation Lab - DirectiveAI',
    description: 'Where next-generation AI technologies are developed.',
    keywords: 'innovation lab, AI development, research facility'
  }
};

function MetadataManager() {
  const location = useLocation();
  const metadata = routeMetadata[location.pathname] || {
    title: 'DirectiveAI',
    description: 'Accuracy Generative Technology',
    keywords: 'DirectiveAI, AGT, AI technology'
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://directiveai.org${location.pathname}`} />
      <link rel="canonical" href={`https://directiveai.org${location.pathname}`} />
    </Helmet>
  );
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

function RouteErrorBoundary() {
  const navigate = useNavigate();
  useEffect(() => {
    const handleError = (error) => {
      console.error('Route Error:', error);
      navigate('/404');
    };
    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, [navigate]);
  return null;
}

const AGTRoutes = {
  Overview: verifyComponent(() => import('./components/pages/AGT/Overview'), 'Overview'),
  Pro: verifyComponent(() => import('./components/pages/AGT/AGTPro'), 'AGTPro'),
  SearchPro: verifyComponent(() => import('./components/pages/AGT/SearchPro'), 'SearchPro'),
  MakerPro: verifyComponent(() => import('./components/pages/AGT/MakerPro'), 'MakerPro'),
  Video: verifyComponent(() => import('./components/pages/AGT/Video'), 'Video'),
  Pricing: verifyComponent(() => import('./components/pages/AGT/Pricing'), 'Pricing'),
  Integration: verifyComponent(() => import('./components/pages/AGT/Integration'), 'Integration'),
  API: verifyComponent(() => import('./components/pages/AGT/API'), 'API'),
  Docs: verifyComponent(() => import('./components/pages/AGT/Docs'), 'Docs'),
  Community: verifyComponent(() => import('./components/pages/AGT/Community'), 'Community'),
  Support: verifyComponent(() => import('./components/pages/AGT/Support'), 'Support'),
  SuccessStories: verifyComponent(() => import('./components/pages/AGT/SuccessStories'), 'SuccessStories'),
  Training: verifyComponent(() => import('./components/pages/AGT/Training'), 'Training'),
  KnowledgeBase: verifyComponent(() => import('./components/pages/AGT/KnowledgeBase'), 'KnowledgeBase'),
  Updates: verifyComponent(() => import('./components/pages/AGT/Updates'), 'Updates')
};

const UpsRoutes = {
  Overview: verifyComponent(() => import('./components/pages/Ups/Overview'), 'UpsOverview'),
  Product: verifyComponent(() => import('./components/pages/Ups/Product'), 'Product'),
  CashFlow: verifyComponent(() => import('./components/pages/Ups/CashFlow'), 'CashFlow'),
  Council: verifyComponent(() => import('./components/pages/Ups/Council'), 'Council'),
  Growth: verifyComponent(() => import('./components/pages/Ups/Growth'), 'Growth'),
  Start: verifyComponent(() => import('./components/pages/Ups/Start'), 'Start'),
  SupplierFinder: verifyComponent(() => import('./components/pages/Ups/SupplierFinder'), 'SupplierFinder'),
  Integration: verifyComponent(() => import('./components/pages/Ups/Integration'), 'Integration'),
  Docs: verifyComponent(() => import('./components/pages/Ups/Docs'), 'Docs'),
  KnowledgeBase: verifyComponent(() => import('./components/pages/Ups/KnowledgeBase'), 'KnowledgeBase'),
  Training: verifyComponent(() => import('./components/pages/Ups/Training'), 'Training'),
  Community: verifyComponent(() => import('./components/pages/Ups/Community'), 'Community'),
  Support: verifyComponent(() => import('./components/pages/Ups/Support'), 'Support'),
  Success: verifyComponent(() => import('./components/pages/Ups/Success'), 'Success')
};

const EduRoutes = {
  Overview: verifyComponent(() => import('./components/pages/Edu/Overview'), 'EduOverview'),
  DirectiveMode: verifyComponent(() => import('./components/pages/Edu/DirectiveMode'), 'DirectiveMode'),
  Academic: verifyComponent(() => import('./components/pages/Edu/Academic'), 'Academic'),
  Growth: verifyComponent(() => import('./components/pages/Edu/Growth'), 'Growth'),
  Materials: verifyComponent(() => import('./components/pages/Edu/Materials'), 'Materials'),
  Stories: verifyComponent(() => import('./components/pages/Edu/Stories'), 'Stories'),
  Pricing: verifyComponent(() => import('./components/pages/Edu/Pricing'), 'EduPricing'),
  Implementation: verifyComponent(() => import('./components/pages/Edu/Implementation'), 'Implementation'),
  Docs: verifyComponent(() => import('./components/pages/Edu/Docs'), 'EduDocs'),
  Training: verifyComponent(() => import('./components/pages/Edu/Training'), 'EduTraining'),
  Support: verifyComponent(() => import('./components/pages/Edu/Support'), 'Support'),
  Community: verifyComponent(() => import('./components/pages/Edu/Community'), 'Community'),
  Access: verifyComponent(() => import('./components/pages/Edu/Access'), 'Access'),
  Guide: verifyComponent(() => import('./components/pages/Edu/Guide'), 'Guide')
};

const ResearchRoutes = {
  Overview: verifyComponent(() => import('./components/pages/Research/Overview'), 'ResearchOverview'),
  ConsciousnessAI: verifyComponent(() => import('./components/pages/Research/ConsciousnessAI'), 'ConsciousnessAI'),
  Lab: verifyComponent(() => import('./components/pages/Research/Lab'), 'Lab'),
  Papers: verifyComponent(() => import('./components/pages/Research/Papers'), 'Papers'),
  Updates: verifyComponent(() => import('./components/pages/Research/Updates'), 'Updates'),
  Partnerships: verifyComponent(() => import('./components/pages/Research/Partnerships'), 'Partnerships'),
  Integrity: verifyComponent(() => import('./components/pages/Research/Integrity'), 'Integrity'),
  AcademicAccess: verifyComponent(() => import('./components/pages/Research/AcademicAccess'), 'AcademicAccess'),
  SubmitPaper: verifyComponent(() => import('./components/pages/Research/SubmitPaper'), 'SubmitPaper'),
  Blog: verifyComponent(() => import('./components/pages/Research/Blog'), 'Blog'),
  Events: verifyComponent(() => import('./components/pages/Research/Events'), 'Events')
};

const CompanyRoutes = {
  About: verifyComponent(() => import('./components/pages/Company/About'), 'About'),
  Leadership: verifyComponent(() => import('./components/pages/Company/Leadership'), 'Leadership'),
  Vision: verifyComponent(() => import('./components/pages/Company/Vision'), 'Vision'),
  Ethics: verifyComponent(() => import('./components/pages/Company/Ethics'), 'Ethics'),
  Environment: verifyComponent(() => import('./components/pages/Company/Environment.js'), 'Environment'),
  Press: verifyComponent(() => import('./components/pages/Company/Press'), 'Press'),
  News: verifyComponent(() => import('./components/pages/Company/News'), 'News'),
  Blog: verifyComponent(() => import('./components/pages/Company/Blog'), 'Blog'),
  Events: verifyComponent(() => import('./components/pages/Company/Events'), 'Events'),
  Announcements: verifyComponent(() => import('./components/pages/Company/Announcements'), 'Announcements'),
  Careers: verifyComponent(() => import('./components/pages/Company/Careers'), 'Careers'),
  Contact: verifyComponent(() => import('./components/pages/Company/Contact'), 'Contact'),
  Support: verifyComponent(() => import('./components/pages/Company/Support'), 'Support')
};

const LegalRoutes = {
  Privacy: verifyComponent(() => import('./components/pages/Legal/Privacy'), 'Privacy'),
  PrivacySecurity: verifyComponent(() => import('./components/pages/Legal/PrivacySecurity'), 'PrivacySecurity'),
  Terms: verifyComponent(() => import('./components/pages/Legal/Terms'), 'Terms'),
  Legal: verifyComponent(() => import('./components/pages/Legal/Legal'), 'Legal'),
  ResponsibleAI: verifyComponent(() => import('./components/pages/Legal/ResponsibleAI'), 'ResponsibleAI'),
  OpenSource: verifyComponent(() => import('./components/pages/Legal/OpenSource'), 'OpenSource'),
  Sitemap: verifyComponent(() => import('./components/pages/Legal/Sitemap'), 'Sitemap'),
  RegionSelect: verifyComponent(() => import('./components/pages/Legal/RegionSelect'), 'RegionSelect')
};

const Home = verifyComponent(() => import('./components/pages/Home'), 'Home');

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-black w-full overflow-x-hidden">
          <MetadataManager />
          <ScrollToTop />
          <RouteErrorBoundary />
          <Header />
          <main className="bg-black">
            <DebugSuspense>
              <Routes>
                <Route path="/" element={<Home />} />
                
                {/* AGT Routes */}
                <Route path="/agt" element={<AGTRoutes.Overview />} />
                <Route path="/agt-pro" element={<AGTRoutes.Pro />} />
                <Route path="/search-pro" element={<AGTRoutes.SearchPro />} />
                <Route path="/maker-pro" element={<AGTRoutes.MakerPro />} />
                <Route path="/video" element={<AGTRoutes.Video />} />
                <Route path="/pricing" element={<AGTRoutes.Pricing />} />
                <Route path="/integration-guide" element={<AGTRoutes.Integration />} />
                <Route path="/api" element={<AGTRoutes.API />} />
                <Route path="/docs" element={<AGTRoutes.Docs />} />
                <Route path="/tutorials" element={<AGTRoutes.Training />} />
                <Route path="/knowledge-base" element={<AGTRoutes.KnowledgeBase />} />
                <Route path="/community" element={<AGTRoutes.Community />} />
                <Route path="/support" element={<AGTRoutes.Support />} />
                <Route path="/success-stories" element={<AGTRoutes.SuccessStories />} />
                <Route path="/updates" element={<AGTRoutes.Updates />} />

                {/* AGT Ups Routes */}
                <Route path="/ups-overview" element={<UpsRoutes.Overview />} />
                <Route path="/agt-product" element={<UpsRoutes.Product />} />
                <Route path="/agt-cash-flow" element={<UpsRoutes.CashFlow />} />
                <Route path="/agt-council" element={<UpsRoutes.Council />} />
                <Route path="/ups-growth" element={<UpsRoutes.Growth />} />
                <Route path="/ups-start" element={<UpsRoutes.Start />} />
                <Route path="/supplier-finder" element={<UpsRoutes.SupplierFinder />} />
                <Route path="/ups-integration" element={<UpsRoutes.Integration />} />
                <Route path="/ups-docs" element={<UpsRoutes.Docs />} />
                <Route path="/ups-knowledge-base" element={<UpsRoutes.KnowledgeBase />} />
                <Route path="/ups-training" element={<UpsRoutes.Training />} />
                <Route path="/ups-community" element={<UpsRoutes.Community />} />
                <Route path="/ups-support" element={<UpsRoutes.Support />} />
                <Route path="/ups-success" element={<UpsRoutes.Success />} />

                {/* AGT Edu Routes */}
                <Route path="/edu" element={<EduRoutes.Overview />} />
                <Route path="/directive-mode" element={<EduRoutes.DirectiveMode />} />
                <Route path="/agt-academic" element={<EduRoutes.Academic />} />
                <Route path="/edu-growth" element={<EduRoutes.Growth />} />
                <Route path="/edu-materials" element={<EduRoutes.Materials />} />
                <Route path="/edu-stories" element={<EduRoutes.Stories />} />
                <Route path="/edu-pricing" element={<EduRoutes.Pricing />} />
                <Route path="/edu-implementation" element={<EduRoutes.Implementation />} />
                <Route path="/edu-docs" element={<EduRoutes.Docs />} />
                <Route path="/edu-training" element={<EduRoutes.Training />} />
                <Route path="/edu-support" element={<EduRoutes.Support />} />
                <Route path="/edu-community" element={<EduRoutes.Community />} />
                <Route path="/edu-access" element={<EduRoutes.Access />} />
                <Route path="/edu-guide" element={<EduRoutes.Guide />} />

                {/* Research Routes */}
                <Route path="/research" element={<ResearchRoutes.Overview />} />
                <Route path="/consciousness-ai" element={<ResearchRoutes.ConsciousnessAI />} />
                <Route path="/lab" element={<ResearchRoutes.Lab />} />
                <Route path="/research-papers" element={<ResearchRoutes.Papers />} />
                <Route path="/research-updates" element={<ResearchRoutes.Updates />} />
                <Route path="/research-partnerships" element={<ResearchRoutes.Partnerships />} />
                <Route path="/research-integrity" element={<ResearchRoutes.Integrity />} />
                <Route path="/academic-access" element={<ResearchRoutes.AcademicAccess />} />
                <Route path="/submit-paper" element={<ResearchRoutes.SubmitPaper />} />
                <Route path="/research-blog" element={<ResearchRoutes.Blog />} />
                <Route path="/events" element={<ResearchRoutes.Events />} />

                {/* Company Routes */}
                <Route path="/about" element={<CompanyRoutes.About />} />
                <Route path="/leadership" element={<CompanyRoutes.Leadership />} />
                <Route path="/vision" element={<CompanyRoutes.Vision />} />
                <Route path="/ethics" element={<CompanyRoutes.Ethics />} />
                <Route path="/environment" element={<CompanyRoutes.Environment />} />
                <Route path="/press" element={<CompanyRoutes.Press />} />
                <Route path="/news" element={<CompanyRoutes.News />} />
                <Route path="/blog" element={<CompanyRoutes.Blog />} />
                <Route path="/events" element={<CompanyRoutes.Events />} />
                <Route path="/announcements" element={<CompanyRoutes.Announcements />} />
                <Route path="/careers" element={<CompanyRoutes.Careers />} />
                <Route path="/contact" element={<CompanyRoutes.Contact />} />
                <Route path="/company-support" element={<CompanyRoutes.Support />} />
                
                {/* Legal */}
                <Route path="/privacy-policy" element={<LegalRoutes.Privacy />} />
                <Route path="/privacy-security" element={<LegalRoutes.PrivacySecurity />} />
                <Route path="/terms" element={<LegalRoutes.Terms />} />
                <Route path="/legal" element={<LegalRoutes.Legal />} />
                <Route path="/responsible-ai" element={<LegalRoutes.ResponsibleAI />} />
                <Route path="/open-source" element={<LegalRoutes.OpenSource />} />
                <Route path="/sitemap" element={<LegalRoutes.Sitemap />} />
                <Route path="/region-select" element={<LegalRoutes.RegionSelect />} />

                {/* 404 Route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </DebugSuspense>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;