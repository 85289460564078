import React from 'react';
import { motion } from 'framer-motion';
import loadingSvg from '../../assets/images/loading/loading.svg';

const Loading = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen flex items-center justify-center bg-black"
    >
      <div className="text-white text-center">
        <div className="h-32 w-32 mx-auto">
          <img
            src={loadingSvg}
            alt="Loading"
            className="w-full h-full"
          />
        </div>
        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="mt-4 text-xl font-medium tracking-wide"
        >
        </motion.p>
      </div>
    </motion.div>
  );
};

export default Loading;